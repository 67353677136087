
/*  */
.main{
    width: 100%;
    min-height: 100%;
    padding: 30px;
}
.mainBox{
    width: 100%;
    min-height: 100%;
    /* margin: 0 auto; */
    display: flex;
}
.mainLeft{
    width: 49%;
    min-height: 100%;
    background-color: #fff;
    margin-right:2%;
    display: inline-block;
}
.leftTitle{
    width: 90%;
    margin: 0 auto;
    height: 50px;
    line-height: 50px;
    text-align: center;
    /* border-bottom: 1px solid #eee; */
}
.content{
    width: 90%;
    margin: 0 auto;
    padding: 15px 0;
}
.contentItem{
    margin-bottom: 15px;
}
.item{
    display: flex;
    margin-bottom: 10px;
}
.radioGroup{
    margin-left: 6%;
}

.ant-wave-target{
    display: none !important;
}
.ant-checkbox-wrapper-checked{
    position: relative;
}
.ant-checkbox-wrapper-checked .radioflag{
    width: 14px;
    height: 14px;
    position: absolute;
    top:0;
    background: url('../../static/image/check.jpg') no-repeat;
    background-size: 100%;
}

.hua{
    width: 200px;
    height: 80px;
    background-position: 100%;
    -webkit-clip-path: polygon(33% 100%,50% 0, 66% 100%);
    
}
.hua1{
    width: 200px;
    height: 80px;
    background-position: 100%;
    clip-path: polygon(33% 0, 17% 100%, calc(100% - 37px) 100%, 66% 0);
    display: block;
}
.hua2 {
    width: 200px;
    height: 80px;
    background-position: 100%;
    clip-path: polygon(17% 0, 0px 100%, calc(100% - 4px) 100%, 81.5% 0);
    display: block;
}
.echartsBox{
    width: 400px;
    height: 400px;
    background-color: '#eee',
}
.checkText{
    border: 1px solid #EEE;
    border-radius: 10px;
    background-color: #fafaf6;
    text-align: center;
}
.checkText img{
    border-radius: 10px 10px 0 0;
}
.mainRight {
    width: 49%;
    min-height: 100%;
    background-color: #fff;
    display: inline-block;
}
.fragrance .fragranceTitle{
    margin-bottom: 15;
}
.fragrance .fragranceTopImg{
    display: flex;
    text-align: center;
}
.fragranceTopTitle{
    margin-top: 5px;
    margin-bottom: 10px;
    font-weight: bold;
}
.optionChart{
    width: 100%;
    padding: 20px;
    /* height: 400px; */
}
.dataMetaImg{
    display: flex;
    text-align: center;
}
.generateImg{
    width: 100%;
    float: left;
    margin-right: 10px;
    background-color: #7bc4e8;
}